var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"price-management-categories"}},[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('app-data-table',{ref:"priceManagementCategoriesTable",attrs:{"table-name":"price-management-categories","busy":_vm.categoriesProductsLoading,"fields":_vm.categoriesProductsFields,"items":_vm.categoriesProducts,"sort-by":"categoryName","small":_vm.$store.getters['app/mdAndDown'],"top-table":{
        disabled: _vm.categoriesProductsLoading,
        showAdd: _vm.canEdit(),
      },"actions-table":{
        showEdit: _vm.canEdit(),
        showDelete: _vm.canEdit(),
      },"bottom-table":{ totalCount: _vm.totalItems }},on:{"table-add":function($event){return _vm.addCategory()},"edit":function($event){return _vm.editCategory($event.item.category)},"delete":function($event){return _vm.removeProduct($event.item)}},scopedSlots:_vm._u([{key:"cell(product)",fn:function(ref){
      var item = ref.item;
return [(_vm.$can('PRICE_MANAGEMENT_EDIT'))?_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",on:{"click":function($event){return _vm.editCategory(item.category)}}},[_vm._v(" "+_vm._s(item.product.name[_vm.lang])+" ")]):_c('span',[_vm._v(" "+_vm._s(item.product.name[_vm.lang])+" ")])]}},{key:"cell(category)",fn:function(ref){
      var item = ref.item;
return [(_vm.$can('PRICE_MANAGEMENT_EDIT'))?_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",on:{"click":function($event){return _vm.editCategory(item.category)}}},[_vm._v(" "+_vm._s(item.category.categoryName[_vm.lang])+" ")]):_c('span',[_vm._v(" "+_vm._s(item.category.categoryName[_vm.lang])+" ")])]}},{key:"cell(price)",fn:function(ref){
      var item = ref.item;
return [_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.upperFirst(_vm.lowerCase(item.category.basePriceExcludingTaxes)))+" "+_vm._s(_vm.unities[item.category.basePriceMode])+" ")])]}}])})],1),_c('PriceManagementCategory',{attrs:{"category":_vm.selectedCategory},on:{"update:category":function($event){return _vm.updateCategories($event)}},model:{value:(_vm.categoryEditionMode),callback:function ($$v) {_vm.categoryEditionMode=$$v},expression:"categoryEditionMode"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }