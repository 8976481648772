<template>
  <div id="price-management-categories">
    <b-card no-body class="mb-0">
      <!-- TABLE -->
      <app-data-table
        ref="priceManagementCategoriesTable"
        table-name="price-management-categories"
        :busy="categoriesProductsLoading"
        :fields="categoriesProductsFields"
        :items="categoriesProducts"
        sort-by="categoryName"
        :small="$store.getters['app/mdAndDown']"
        :top-table="{
          disabled: categoriesProductsLoading,
          showAdd: canEdit(),
        }"
        :actions-table="{
          showEdit: canEdit(),
          showDelete: canEdit(),
        }"
        :bottom-table="{ totalCount: totalItems }"
        @table-add="addCategory()"
        @edit="editCategory($event.item.category)"
        @delete="removeProduct($event.item)"
      >
        <!-- CELL -->
        <!--product-->
        <template #cell(product)="{ item }">
          <b-link v-if="$can('PRICE_MANAGEMENT_EDIT')" class="font-weight-bold d-block text-nowrap" @click="editCategory(item.category)">
            {{ item.product.name[lang] }}
          </b-link>
          <span v-else>
            {{ item.product.name[lang] }}
          </span>
        </template>
        <!--category-->
        <template #cell(category)="{ item }">
          <b-link v-if="$can('PRICE_MANAGEMENT_EDIT')" class="font-weight-bold d-block text-nowrap" @click="editCategory(item.category)">
            {{ item.category.categoryName[lang] }}
          </b-link>
          <span v-else>
            {{ item.category.categoryName[lang] }}
          </span>
        </template>
        <!-- price -->
        <template #cell(price)="{ item }">
          <b-badge pill variant="light-primary">
            {{ upperFirst(lowerCase(item.category.basePriceExcludingTaxes)) }}
            {{ unities[item.category.basePriceMode] }}
          </b-badge>
        </template>
      </app-data-table>
    </b-card>

    <PriceManagementCategory
      v-model="categoryEditionMode"
      :category="selectedCategory"
      @update:category="updateCategories($event)"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { upperFirst, lowerCase, isEqual } from 'lodash'
import { mapState } from 'vuex'

import AppDataTable from '@/components/AppDataTable.vue'
import PriceManagementCategory from './PriceManagementCategory.vue'

export default defineComponent({
  name: 'PriceManagementCategories',

  components: {
    PriceManagementCategory,
    AppDataTable,
  },

  props: {
    priceCategories: {
      type: Array,
      required: true,
    },
  },

  setup(_props, ctx) {
    const { $store } = ctx.root

    const unities = {
      currency: $store.state.appConfig.currency,
      percent: '%',
    }

    return {
      unities,
    }
  },

  data() {
    return {
      categoriesProducts: [],
      categoriesProductsLoading: true,
      categoryEditionMode: null,
      selectedCategory: {},
      categoriesProductsFields: [
        { key: 'product', label: this.$t('price_management.group.products') },
        { key: 'category', label: this.$t('price_management.category'), sortable: true },
        { key: 'price', label: this.$t('price_management.price') },
      ],
    }
  },

  computed: {
    ...mapState('appConfig', ['lang']),

    totalItems() {
      return this.categoriesProducts ? this.categoriesProducts.length : 0
    },
  },
  mounted() {
    this.updateCategoriesProducts()
  },

  methods: {
    upperFirst,
    lowerCase,

    canEdit() {
      return (this.$can('PRICE_MANAGEMENT_ADD') && (this.$route.name === 'priceManagementAdd' || this.$route.name === 'priceManagementDuplicate')) || (this.$can('PRICE_MANAGEMENT_EDIT') && this.$route.name === 'priceManagementEdit')
    },

    addCategory() {
      this.selectedIndex = this.priceCategories.length + 1
      this.selectedCategory = {
        products: [],
        basePriceExcludingTaxes: null,
        basePriceMode: null,
        categoryId: null,
        categoryName: null,
      }
      this.categoryEditionMode = 'add'
    },

    removeProduct(item) {
      const indexPriceCategory = this.priceCategories.findIndex(
        priceCategory => priceCategory.categoryId === item.category.categoryId && isEqual(priceCategory.products, item.category.products),
      )
      if (indexPriceCategory !== -1) {
        this.priceCategories[indexPriceCategory].products = this.priceCategories[indexPriceCategory].products.filter(product => product.id !== item.product.id)
        if (this.priceCategories[indexPriceCategory].products.length === 0) {
          this.priceCategories.splice(indexPriceCategory, 1)
        }
      }
      this.updateCategoriesProducts()
    },

    editCategory(category) {
      this.selectedCategory = category
      this.categoryEditionMode = 'edit'
    },

    duplicateCategory(category) {
      this.selectedCategory = category
      this.categoryEditionMode = 'duplicate'
    },

    updateCategories(event) {
      event.newCategory.products.forEach(product => {
        const copyNewCategory = this._cloneDeep(event.newCategory)

        const samePriceCategoryIndex = this.priceCategories.findIndex(priceCategory => priceCategory.products[0].id === product.id)

        const mainCategoryProduct = event.categories.find(category => product.mainCategory.id === category.id)
        copyNewCategory.categoryId = mainCategoryProduct.id
        copyNewCategory.categoryName = mainCategoryProduct.name
        copyNewCategory.products = [product]

        if (samePriceCategoryIndex === -1) {
          this.priceCategories.push({ ...copyNewCategory, id: null })
        } else {
          this.priceCategories[samePriceCategoryIndex] = copyNewCategory
        }
      })
      this.updateCategoriesProducts()
      this.$emit('update:price-categories', this.priceCategories)
    },

    updateCategoriesProducts() {
      this.categoriesProductsLoading = true
      const products = []
      if (this.priceCategories) {
        this.priceCategories.forEach(priceCategory => {
          const formattedProduct = {
            product: priceCategory.products[0],
            category: priceCategory,
          }
          products.push(formattedProduct)
        })
      }
      this.categoriesProducts = products
      this.categoriesProductsLoading = false
    },
  },
})
</script>
